import React from 'react'
import { Link } from 'gatsby'
import { useTranslation } from 'react-i18next'

import SEO from "../components/seo"

const NotFoundPage = props => {
  const { t, i18n } = useTranslation('404');

  return(
    <>
      <SEO title={t('title')} lang={props.pageContext.language}/>
      <div className='success-container'>
        <h1 className='title'>{t('h1')}</h1>
        <p className='main-text'>{t('p')}</p>
        <Link to={`/${i18n.language}/portfolio`}>{t('cta')}</Link>
      </div>
    </>
  )
}

export default  NotFoundPage
